import React, { useState } from 'react';
import * as patronscanLogo from '../../../../../../theme/src/images/patronscan-logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { Menu } from './menu';
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Header = () => {
  const [menuVisible, setMenuVisibility] = useState(false);

  const toggleMenuVisibility = () => setMenuVisibility(!menuVisible);


  return (
    <header className="fixed bg-white shadow-lg z-50 w-full">
      <div className="flex flex-row justify-between items-center px-4 py-2 xl:py-0 xl:px-6">
        <AniLink className="xl:w-auto" style={{ display: 'content' }} fade to="/">
          <img className="max-w-logo w-full opacity-100 hover:opacity-75 transition-opacity" src={patronscanLogo} alt="Patronscan Logo" />
        </AniLink>
        <Menu className="hidden xl:inline-flex xl:w-full justify-end items-center" />
        <button className="focus:outline-none" aria-label="menu" onClick={() => toggleMenuVisibility()}>
          <FontAwesomeIcon className="xl:hidden" icon={faBars} size="lg" />
        </button>
      </div>
      {menuVisible ? <Menu closeMenu={toggleMenuVisibility} className="pr-8 pb-8 pl-8 pt-4" /> : ''}
    </header>
  )
}

export default Header;
